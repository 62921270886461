import React, { useState, useRef, useEffect } from "react";
import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import CustomSwiper from "@/components/Swiper/CustomSwiper";
import CustomSwiperSlide from "@/components/Swiper/CustomSwiperSlide";
import ExpandingButton from "@/components/Buttons/ExpandingButton";
import layerImg from "@/assets/homepage/layer-1.png";
import useCoverage from "@/hooks/useCoverage";
import useLocale from "@/hooks/useLocale";
import localizePath from "@/functions/localizePath";
import localizeContent from "@/functions/localizeContent";

export default function OperationalCoverage({
  path = "operational-coverage",
  coverageStyles: {
    color,
    border,
    background,
    vectorShow,
    btnStyle,
    hover,
    btnColor,
    btnBGColor,
    hoverBGColor,
    hoverColor,
  },
}) {
  const coverage = useCoverage();
  const locale = useLocale();
  const [active, setActive] = useState(coverage[0].id);
  const [ref, setRef] = useState();
  const containerRef = useRef(null);
  const [showFade, setShowFade] = useState({
    left: false,
    right: false,
    fadeOpacity: 0.6,
  });

  const handleChange = (id, idx) => {
    setActive(id);
    if (ref?.current) {
      ref.current.swiper?.slideTo(idx);
    }
  };

  const activeCoverage = coverage.find((it) => it.id === active);
  const images = coverage.map((item) => item.image);

  const getRef = (ref) => {
    setRef(ref);
  };

  const checkScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

    const isDarkBackground = background === "bg-purple";
    const fadeOpacity = isDarkBackground ? 0.9 : 0.2;

    setShowFade({
      left: scrollLeft > 0,
      right: scrollLeft < scrollWidth - clientWidth,
      fadeOpacity: fadeOpacity,
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    checkScroll();
    container.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", checkScroll);
    return () => {
      container.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", checkScroll);
    };
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className="container flex flex-col pt-20 md:pt-[131px]">
        <span className="text-pink text-lg font-semibold">
          <CustomSourceFlowText
            title="OPERATIONAL COVERAGE"
            path={`dynamic1-subtitle.${path}`}
            locale={locale}
          />
        </span>
        <span className={`${color} text-xl md:text-3xl lg:text-[50px]/[60px]`}>
          <CustomSourceFlowText
            title="Where does our work take us?"
            path={`dynamic1-heading.${path}`}
            locale={locale}
          />
        </span>
        <div className="relative">
          <div
            ref={containerRef}
            className={`${border} flex gap-8 border-b-[1px] pt-9 overflow-x-auto no-scrollbar`}
          >
            {coverage.map((data, idx) => (
              <div
                key={data.id}
                className={`${
                  active === data.id ? `font-medium ${color}` : "text-grey-300"
                } pb-4 text-xl/8 cursor-pointer group/item`}
                onClick={() => handleChange(data.id, idx)}
                role="button"
                id={`tab-${data.id}`}
              >
                <span className={`whitespace-nowrap ${hover}`}>
                  {localizeContent(data, "name", locale)}
                </span>
                <span
                  className={`bg-${btnStyle} h-[3px] transition-all ${
                    active === data.id ? "w-full" : "w-0"
                  } block group-hover/item:w-full`}
                />
              </div>
            ))}
          </div>
          {showFade.left && (
            <div
              className="fade-left sm:block md:block lg:hidden xl:hidden 2xl:hidden"
              style={{ opacity: showFade.fadeOpacity }}
            />
          )}
          {showFade.right && (
            <div
              className="fade-right sm:block md:block lg:hidden xl:hidden 2xl:hidden"
              style={{
                opacity: showFade.fadeOpacity,
              }}
            />
          )}
        </div>

        {activeCoverage && (
          <div className="operational-swiper-button-wrapper flex justify-between pt-5 md:pt-[77px] pb-20 sm:pb-[106px] gap-4 flex-col lg:flex-row lg:gap-[110px] z-10">
            <div className={`${color} w-full`}>
              <div className="mb-[50px]">
                <p className="text-xl/8">
                  {localizeContent(activeCoverage, "description", locale)}
                </p>
                <div className="text-xl font-medium flex flex-wrap gap-4 items-center pt-5">
                  {activeCoverage.countries.map((country, index) => (
                    <React.Fragment key={country.id}>
                      <span>{localizeContent(country, "name", locale)}</span>
                      {index !== activeCoverage.countries.length - 1 && (
                        <div className={`${background} w-2 h-2`} />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <ExpandingButton
                url={localizePath("/contact", locale)}
                color={btnColor}
                bgColor={btnBGColor}
                hoverBGColor={hoverBGColor}
                hoverColor={hoverColor}
              >
                <CustomSourceFlowText
                  title="Contact us"
                  path={`dynamic1.operational.contactus`}
                  locale={locale}
                />
              </ExpandingButton>
            </div>
            <div className="operational-swiper-container lg:max-w-[50%] w-full operation relative">
              <div>
                <CustomSwiper
                  view={{ desktop: 1.1, laptop: 1.1, md: 1.1, mobile: 1 }}
                  space={19}
                  getRef={getRef}
                  hideArrowLeft={
                    ref?.current?.swiper && ref?.current?.swiper.isBeginning
                  }
                  hideArrowRight={
                    ref?.current?.swiper && ref?.current?.swiper.isEnd
                  }
                  loop={false}
                  data={coverage}
                  setActive={setActive}
                  navigate={true}
                  navStyles={{
                    container:
                      "absolute flex items-center right-0 z-10 w-full h-[527px]",
                    left: "bg-white absolute left-[-18px] md:left-[-42px] p-2 md:p-0",
                    right:
                      "bg-white absolute right-[-18px] sm:right-[22px] p-2 md:p-0",
                  }}
                >
                  {images.map((item, idx) => (
                    <CustomSwiperSlide key={idx}>
                      <div className="item h-full">
                        <img
                          src={item}
                          alt="city image"
                          width={612}
                          height={527}
                          className="w-full h-full sm:h-[527px] object-cover"
                        />
                      </div>
                    </CustomSwiperSlide>
                  ))}
                </CustomSwiper>
              </div>
            </div>
          </div>
        )}
      </div>
      {!vectorShow && (
        <img
          className="absolute z-0 top-[75%] right-0"
          src={layerImg ?? ""}
          width={1660}
          height={780}
          alt="image-corer"
        />
      )}
    </div>
  );
}
